/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Phone } from 'emotion-icons/material'
import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Accordion,
  Billboard,
  Column,
  Columns,
  Image,
  Link,
  LinkButton,
  List,
  ListItem,
  PackageCard,
  Stack,
  Typography,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { graphql } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const CityTemplate = ({ data }) => {
  const { Meta_Description, State, City, Page_Path, Meta_Title, Promo_Code } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const brandyPackageList = [
    'viv-sigil-starter',
    'viv-sigil-premium',
    'viv-sigil-premiumplus',
  ]

  const packageList = brandyPackageList
    .map((packageId) =>
      data?.allContentfulPackage?.edges?.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const { rotatedNumber } = useMapi()

  const indoorCameraStairsData = {
    ...data.indoorCameraStairs?.cloudinary[0]?.gatsbyImageData,
    context: data.indoorCameraStairs?.cloudinary[0]?.context,
  }
  const indoorCameraStairsMobileData = {
    ...data.indoorCameraStairsMobile?.cloudinary[0]?.gatsbyImageData,
    context: data.indoorCameraStairsMobile?.cloudinary[0]?.context,
  }
  const equipmentLineupData = {
    ...data.equipmentLineup?.cloudinary[0]?.gatsbyImageData,
    context: data.equipmentLineup?.cloudinary[0]?.context,
  }
  const smartSensorDoorWindowContainedCardData = {
    ...data.smartSensorDoorWindowContainedCard?.cloudinary[0]?.gatsbyImageData,
    context: data.smartSensorDoorWindowContainedCard?.cloudinary[0]?.context,
  }
  const doorbellCameraContainedCardData = {
    ...data.doorbellCameraContainedCard?.cloudinary[0]?.gatsbyImageData,
    context: data.doorbellCameraContainedCard?.cloudinary[0]?.context,
  }

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: Page_Path,
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: 'vivintsource',
      },
      pagePath: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            variant="full"
            mainContent={
              <>
                <Typography variant="h1">
                  Vivint Home Security in {City}, {State_Name}
                </Typography>
                <Typography variant="h4">
                  Get custom-built security packages in your area.
                </Typography>
                <LinkButton
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  variant="hero"
                  leftIcon={<Phone />}
                >
                  Call {rotatedNumber}
                </LinkButton>
              </>
            }
            image={<Image data={indoorCameraStairsData} isImageCritical />}
            mobileImage={
              <Image data={indoorCameraStairsMobileData} isImageCritical />
            }
          />
          <VariableContent
            alignMainContent="center"
            backgroundColor="dark"
            mainContent={
              <>
                <Image data={equipmentLineupData} />
                <br />
                <br />
                <Typography variant="h2">
                  Home security systems customized for your needs in {City},{' '}
                  {State_Name}
                </Typography>
                <Typography>
                  Custom home security is simple with Vivint. Our products
                  collaborate seamlessly for a personalized home security setup.
                  From doors and windows to any overlooked areas, we&lsquo;ve
                  got them all covered.
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns>
                {packageList.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>

          <VariableContent>
            <Stack spacing="xxl">
              <Columns>
                <Column>
                  <Image data={smartSensorDoorWindowContainedCardData} />
                  <br />
                  <br />
                  <Typography variant="h5">
                    Security sensors for all entry points
                  </Typography>
                  <Typography variant="body">
                    {City}, {State_Name} residents get security sensors for
                    every entry point. Our security sensors continuously survey
                    your doors and windows to deliver comprehensive protection
                    against burglaries and unwelcome intruders. If there&lsquo;s
                    an unauthorized entry attempt, an alarm will activate,
                    promptly alerting our vigilant monitoring team.
                  </Typography>
                </Column>
                <Column>
                  <Image data={doorbellCameraContainedCardData} />
                  <br />
                  <br />
                  <Typography variant="h5">
                    Smart Video Doorbell Cameras in {City}, {State_Name}
                  </Typography>
                  <Typography variant="body">
                    Stop package theft before it happens with the Doorbell
                    Camera Pro. This video doorbell camera proactively protects
                    your deliveries using Smart Deter. Equipped with computer
                    vision chip technology, the Doorbell Camera Pro intuitively
                    detects deliveries and lurkers.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>

          <VariableContent
            alignMainContent="center"
            mainContent={
              <Typography variant="h2">Frequently Asked Questions</Typography>
            }
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion
                items={[
                  {
                    title: 'How much is Vivint?',
                    content: (
                      <>
                        <Typography variant="body">
                          The price of Vivint security services is dependent on
                          the package you select.
                        </Typography>
                        <Typography variant="body">
                          To learn more, call{' '}
                          <Link to={`tel:${rotatedNumber}`}>
                            {rotatedNumber}
                          </Link>{' '}
                          to speak with a customer service representative.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'What should I look for in a home security system?',
                    content: (
                      <>
                        <Typography variant="body">
                          <strong>
                            When evaluating a home security system, consider the
                            following key factors:
                          </strong>
                        </Typography>
                        <List>
                          <ListItem>
                            <Typography variant="body">
                              Comprehensive Coverage: Ensure the system can
                              cover all vulnerable entry points such as doors
                              and windows, and any blind spots in and around
                              your home.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              Monitoring Options: Choose between professional
                              monitoring services, self-monitoring via
                              smartphone apps, or a combination of both to suit
                              your needs and budget.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              Smart Integration: Look for systems that integrate
                              with other smart home devices like cameras,
                              doorbell cameras, and smart locks for enhanced
                              convenience and security.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              Reliability and Support: Prioritize systems with
                              reliable hardware and 24/7 customer support to
                              address any issues promptly and keep your home
                              secure.
                            </Typography>
                          </ListItem>
                        </List>
                      </>
                    ),
                  },
                  {
                    title: 'Are home security systems worth the money?',
                    content: (
                      <>
                        <Typography variant="body">
                          Yes. Adding a home security system is a good
                          investment.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title:
                      'What is the Vivint Security Customer Service number?',
                    content: (
                      <>
                        <Typography variant="body">
                          The Vivint customer service number is{' '}
                          <Link to={`tel:${rotatedNumber}`}>
                            <Link to="tel:1-801-377-9111">1-801-377-9111</Link>
                          </Link>
                          .
                        </Typography>
                        <Typography variant="body">
                          Agents are available 7:00 a.m.–1:00 a.m. EST M-F to
                          assist with technical support and customer service
                          issues.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Do you own your equipment from Vivint?',
                    content: (
                      <>
                        <Typography variant="body">
                          It’s possible to own your Vivint equipment. You may
                          either lease equipment or purchase your security
                          equipment upfront.
                        </Typography>
                      </>
                    ),
                  },
                  {
                    title: 'Are any cameras compatible with Vivint?',
                    content: (
                      <>
                        <Typography variant="body">
                          Only Vivint cameras are compatible with Vivint
                          equipment. Those cameras include:
                        </Typography>
                        <List>
                          <ListItem>
                            <Typography variant="body">
                              <Link to="/equipment/doorbell-camera">
                                <strong>Vivint Doorbell Camera Pro™</strong>
                              </Link>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              <Link to="/equipment/outdoor-security-camera">
                                <strong>
                                  Vivint’s Outdoor Security Camera
                                </strong>
                              </Link>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography variant="body">
                              <Link to="/equipment/ping-camera">
                                <strong>
                                  Vivint Ping™ Home Security Camera
                                </strong>
                              </Link>
                            </Typography>
                          </ListItem>
                        </List>
                        <Typography variant="body">
                          See what other equipment is{' '}
                          <Link to="/equipment">
                            compatible with your Vivint security system
                          </Link>
                          .
                        </Typography>
                      </>
                    ),
                  },
                ]}
              />
            </Stack>
          </VariableContent>
        </>
      }
    />
  )
}

CityTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery($pagePath: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerVivintAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          City
          State
          Page_Path
          Meta_Title
          Meta_Description
          Promo_Code
        }
      }
    }
    indoorCameraStairs: contentfulMedia(
      id: { eq: "16b698b7-4e5a-587a-adc3-d27df07b7924" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    indoorCameraStairsMobile: contentfulMedia(
      id: { eq: "80933587-1688-5608-a6a9-29943acd73f0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    equipmentLineup: contentfulMedia(
      id: { eq: "ed8721da-efce-574f-8489-1d8c1f4276ff" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    smartSensorDoorWindowContainedCard: contentfulMedia(
      id: { eq: "9d935d5e-f3e4-5f82-a0d6-cab258a1cbcd" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    doorbellCameraContainedCard: contentfulMedia(
      id: { eq: "3e678ed7-919e-53a2-9c8d-d39efbcf43ec" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    allContentfulHeader(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
